import { NgModule } from '@angular/core';
import { MatMenuModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatFormFieldModule, MatTableModule, MatTabsModule, MatDialogModule, MatSelectModule, MatToolbarModule, MatGridListModule, MatExpansionModule, MatListModule, MatBadgeModule, MatChipsModule, MatProgressSpinnerModule } from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';

@NgModule({
    imports: [
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatMenuModule,
        MatInputModule,
        MatFormFieldModule,
        MatTableModule, 
        MatTabsModule, 
        MatDialogModule, 
        MatSelectModule, 
        MatToolbarModule,
        MatGridListModule,
        MatExpansionModule,
        MatListModule,
        CdkTableModule,
        MatBadgeModule,
        MatChipsModule,
        MatProgressSpinnerModule
    ],
    exports: [
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatMenuModule,
        MatInputModule,
        MatFormFieldModule,
        MatTableModule, 
        MatTabsModule, 
        MatDialogModule, 
        MatSelectModule, 
        MatToolbarModule,
        MatGridListModule,
        MatExpansionModule,
        MatListModule,
        CdkTableModule,
        MatBadgeModule,
        MatChipsModule,
        MatProgressSpinnerModule
    ],
})
export class AngularMaterialModule { }