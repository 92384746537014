import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  sw_sub: Subscription;
  
  constructor(private swUpdate: SwUpdate) {}

  ngOnInit() {
    this.swUpdate.checkForUpdate();

    this.sw_sub = this.swUpdate.available.subscribe(event => {
      if (confirm('Update Available. Refresh the page now.')) {
        location.reload(true);
      } else {
        location.reload(true);
      }
    });
  }

}
