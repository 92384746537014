import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Item } from '../../item';
import { PosService } from '../../pos.service';
import { DatabaseService } from '../../database.service';

import { CheckoutSummaryComponent } from './../checkout-summary/checkout-summary.component';
import { QrDialogComponent } from './qr-dialog/qr-dialog.component';
import { CashDialogComponent } from './cash-dialog/cash-dialog.component';
import { UtangDialogComponent } from './utang-dialog/utang-dialog.component';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CashlessDialogComponent } from './cashless-dialog/cashless-dialog.component';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  @Output() switchTab: EventEmitter<string> = new EventEmitter<string>();

  ticket: Item[] = [];

  cart_total = 0;
  cart_num_items = 0;
  students: any;
  users: any;
  school_uid: string;
  payload: object;

  displayedColumns: string[] = ['item', 'quantity', 'price'];

  constructor(private ticketSync: PosService, private db: DatabaseService, public dialog: MatDialog) { }

  // Sync with ticketSync service on init
  ngOnInit() {
    this.ticketSync.currentTicket.subscribe(data => {
      this.ticket = data;
    });
    this.ticketSync.currentTotal.subscribe(total => this.cart_total = total);
    this.ticketSync.currentCartNum.subscribe(num => this.cart_num_items = num);
    this.school_uid = this.db.school_uid;
    this.payload = {
      school_uid: this.school_uid
    };
  }
  /** Gets the total cost of all transactions. */
  getTotalCost() {
    // return this.transactions.map(t => t.cost).reduce((acc, value) => acc + value, 0);
  }

  // Add item to ticket.
  addItem(item: Item) {
    // If the item already exists, add 1 to quantity
    if (this.ticket.includes(item)) {
      this.ticket[this.ticket.indexOf(item)].quantity += 1;
    } else {
      this.ticket.push(item);
    }
    this.syncTicket();
    this.calculateTotal();
  }

  // Remove item from ticket
  removeItem(item: Item) {
    // Check if item is in array
    const prompt = confirm(`Are you sure to remove ${item.name} from the order?`);

    if (!prompt) return;

    if (this.ticket.includes(item)) {
      // Splice the element out of the array
      const index = this.ticket.indexOf(item);
      if (index > -1) {
        // Set item quantity back to 1 (thus when readded, quantity isn't 0)
        this.ticket[this.ticket.indexOf(item)].quantity = 1;
        this.ticket.splice(index, 1);
      }
    }
    this.syncTicket();
    this.calculateTotal();
  }

  // Reduce quantity by one
  subtractOne(item: Item) {
    // Check if last item, if so, use remove method
    if (this.ticket[this.ticket.indexOf(item)].quantity === 1) {
      this.removeItem(item);
    } else {
      this.ticket[this.ticket.indexOf(item)].quantity = this.ticket[this.ticket.indexOf(item)].quantity - 1;
    }
    this.syncTicket();
    this.calculateTotal();
  }

  // Calculate cart total
  calculateTotal() {
    let total = 0;
    let cartitems = 0;
    // Multiply item price by item quantity, add to total
    this.ticket.forEach(function(item: Item) {
      total += (item.price * item.quantity);
      cartitems += item.quantity;
    });
    this.cart_total = total;
    this.cart_num_items = cartitems;
    // Sync total with ticketSync service.
    this.ticketSync.updateNumItems(this.cart_num_items);
    this.ticketSync.updateTotal(this.cart_total);
  }

  // Remove all items from cart
  clearCart() {
    // Reduce back to initial quantity (1 vs 0 for re-add)
    this.ticket.forEach(function(item: Item) {
      item.quantity = 1;
    });
    // Empty local ticket variable then sync
    this.ticket = [];
    this.syncTicket();
    this.calculateTotal();
  }

  syncTicket() {
    this.ticketSync.changeTicket(this.ticket);
  }

  checkout() {
    if (this.ticket.length === 0) {
      return;
    }
    const payload = {
      school_uid: this.db.school_uid,
      order_data: {
        itemList: this.ticket, 
        total: this.cart_total, 
        cart_num_items: this.cart_num_items
      },
      scan_op: 'buy'
    };

    return payload;
  }

  checkoutSummary(data) {
    let dialogRef = this.dialog.open(CheckoutSummaryComponent, {
      width: '50%',
      height: '85%',
      data
    });

    return dialogRef.afterClosed().toPromise().then(result => {
      console.log('The checkout summary dialog was closed and result', result);

      if (result) {
        return result;
      } else {
        return;
      }
    });
  }

  checkoutQRCode() {
    const payload = this.checkout();
    payload['type'] = 'cashless';

    if (payload) {
      this.checkoutSummary(payload).then((result) => {
        if (result) {
          this.openQRDialog(payload);
        }
      });
    }
  }

  checkoutCash() {
    const payload = this.checkout();
    payload['type'] = 'cash';

    if (payload) {
      this.checkoutSummary(payload).then((result) => {
        if (result) {
          this.openCashDialog(payload);
        }
      });
    }
  }

  checkoutUtang() {
    const payload = this.checkout();
    payload['type'] = 'utang';

    if (payload) {
      this.checkoutSummary(payload).then((result) => {
        if (result) {
          this.openUtangDialog(payload);
        }
      });
    }
  }

  openQRDialog(data): void {
    let dialogRef = this.dialog.open(QrDialogComponent, {
      width: '50%',
      height: '25%',
      data
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('The QR dialog was closed and result', result);

      if (result) {
        this.clearCart();
        this.switchTab.emit('pos');
      }
    });
  }

  openCashDialog(data): void {
    let dialogRef = this.dialog.open(CashDialogComponent, {
      width: '50%',
      height: '65%',
      data
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('The dialog was closed');
      if (result) {
        this.clearCart();
        this.switchTab.emit('pos');
      }
    });
  }

  openUtangDialog(data): void {
    let dialogRef = this.dialog.open(UtangDialogComponent, {
      width: '50%',
      height: '50%',
      data
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('The dialog was closed');
      if (result) {
        this.clearCart();
        this.switchTab.emit('pos');
      }
    });
  }

}
