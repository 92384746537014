import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../database.service';
import { Order, Item } from '../../item';
import * as _ from 'lodash';
import { LineItemModalComponent } from '../../home/history/line-item-modal/line-item-modal.component';
import { AuthService } from '../../core/auth.service';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { NotifyService } from '../../core/notify.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  history;

  displayRows = 25;
  rowOptions = [25, 50, 100, 200];
  school_uid: string;
  payload: object;

  constructor(private db: DatabaseService, private authService: AuthService,
    public dialog: MatDialog, private notify: NotifyService) {
    this.school_uid = this.db.school_uid;
    this.payload = {
      school_uid: this.school_uid
    };
  }

  ngOnInit() {
    this.renderNewRows();
  }

  renderNewRows() {
    this.db.updateNumRequested(this.displayRows);
    this.getHistory();
  }

  getHistory() {
    this.db.getSnapshot(this.payload).subscribe((history) => {
      this.history = _.orderBy(history, ['order_number'], ['desc']);
    });
  }

  openDialog(lineItem: Order): void {
    const dialogRef = this.dialog.open(LineItemModalComponent, {
      width: '60%',
      height: '80%',
      data: { numItems: lineItem.cart_num_items,
        order_number: lineItem.order_number,
        items: lineItem.items,
        total: lineItem.cart_total,
        type: lineItem.type
      }
    });
  }

  deleteOrder(id) {
    if (this.authService.admin) {
      this.db.deleteOrder(id);
    } else {
      this.notify.update('You do not have admin privileges.', 'error');
    }
  }

}
