import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../database.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {QrDialogComponent} from './../ticket/qr-dialog/qr-dialog.component';
import * as _ from 'lodash';
import { Order, Item } from '../../item';
import { LineItemModalComponent } from '../history/line-item-modal/line-item-modal.component';

@Component({
  selector: 'app-check-balance',
  templateUrl: './check-balance.component.html',
  styleUrls: ['./check-balance.component.scss']
})
export class CheckBalanceComponent implements OnInit {

  school_uid: string;
  payload: object;
  balance_history: any = [];
  scanned_person: any;
  scanned_type: string;
  orders: any = [];
  
  constructor(private db: DatabaseService, public dialog: MatDialog) { }

  ngOnInit() {
    const payload = {
      school_uid: this.db.school_uid,
      scan_op: 'check_balance'
    };
    this.openQRDialog(payload);
  }

  openQRDialog(data): void {
    let dialogRef = this.dialog.open(QrDialogComponent, {
      width: '50%',
      height: '25%',
      data
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('The dialog was closed', result);

      if (result) {
        result['school_uid'] = this.db.school_uid;
        this.checkBalance(result);
      }
    });
  }

  openDialog(lineItem: Order): void {
    const dialogRef = this.dialog.open(LineItemModalComponent, {
      width: '60%',
      height: '80%',
      data: { numItems: lineItem.cart_num_items,
        order_number: lineItem.order_number,
        items: lineItem.items,
        total: lineItem.cart_total,
        type: lineItem.type
      }
    });
  }

  checkBalance(checker_data) {
    console.log('checker_data', checker_data);

    let history_payload = checker_data;

    this.db.checkBalance(history_payload).then((list) => {
      this.db.getProfile(history_payload).then((user_result) => {
        const balance_history = list.val();
        const profile = user_result.val();
        const promises = [];

        this.scanned_person = profile;
        this.scanned_type = checker_data.buyer_type;

        _.forEach(balance_history, (order) => {
          if (order['order_id']) {
            history_payload['order_id'] = order['order_id'];
            promises.push(
              this.db.getOrder(history_payload)
            );
          }
        });

        Promise.all(promises).then((result) => {
          console.log('result', result);
          _.map(result, (obj) => {
            // console.log('obj', obj.key, obj.val());
            const order_obj = obj.val();
            order_obj['order_id'] = obj.key;
            console.log('order_obj', order_obj);

            this.orders.push(order_obj);
          });
          console.log('this.orders', this.orders);

          this.balance_history = _.orderBy(_.map(balance_history, (obj) => {
            if (obj.order_id) {
              const order_details = _.find(this.orders, {'order_id': obj.order_id});
              obj['items'] = order_details['items'];
              obj['order_number'] = order_details['order_number'];
              obj['cart_total'] = order_details['cart_total'];
            }
            return obj;
          }), ['date'], ['desc']);
          console.log('balance_history', balance_history);
        });
      });
    });
  }

}
