import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../database.service';
import { Order, Item } from '../../item';
import { LineItemModalComponent } from './line-item-modal/line-item-modal.component';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  history: any;
  school_uid: string;
  payload: object;

  constructor(private db: DatabaseService, public dialog: MatDialog) {
    this.school_uid = this.db.school_uid;
    this.payload = {
      school_uid: this.school_uid
    };
  }

  ngOnInit() {
    this.db.updateNumRequested(10);
    this.getHistory();
  }

  getHistory() {
    this.db.getSnapshot(this.payload).subscribe((history) => {
      this.history = history;
    });
  }

  openDialog(lineItem: Order): void {
    const dialogRef = this.dialog.open(LineItemModalComponent, {
      width: '300px',
      data: { numItems: lineItem.cart_num_items,
        order_number: lineItem.order_number,
        items: lineItem.items,
        total: lineItem.cart_total }
    });
  }

}
