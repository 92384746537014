import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { lowerCase } from 'lodash';

@Component({
  selector: 'app-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.scss']
})
export class CheckoutSummaryComponent implements OnInit {

  displayedColumns: string[];
  type_color: string;

  constructor(public dialogRef: MatDialogRef<CheckoutSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log('data', data);
      this.displayedColumns = ['item', 'quantity', 'amount'];

      if (lowerCase(this.data.type) === 'cashless') {
        this.type_color = 'primary';
      }
      if (lowerCase(this.data.type) === 'cash') {
        this.type_color = 'warn';
      }
      if (lowerCase(this.data.type) === 'utang') {
        this.type_color = 'accent';
      }
    }

  ngOnInit() {
  }

  checkout() {
    this.dialogRef.close(true);
  }

}
