import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Item, Order } from '../../item';
import { PosService } from '../../pos.service';
import { DatabaseService } from '../../database.service';
// import {MatTabsModule} from '@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {

  products = [];
  productTypes = ['Drink', 'Food'];
  ticket: Item[];
  cart_total = 0;
  cart_num_items = 0;
  items;
  school_uid: string;
  payload: object;

  constructor(private ticketSync: PosService, private db: DatabaseService) { }

  ngOnInit() {
    this.ticketSync.currentTicket.subscribe(data => this.ticket = data);
    this.ticketSync.currentTotal.subscribe(total => this.cart_total = total);
    this.ticketSync.currentCartNum.subscribe(num => this.cart_num_items);
    this.school_uid = this.db.school_uid;
    this.payload = {
      school_uid: this.school_uid
    };
    this.getDrink();
    this.getFood();
  }

  getDrink() {
    console.log('payload', this.payload);

    this.db.getDrink(this.payload).subscribe((drink) => {
      this.products[0] = drink;
      console.log('drink', drink);
    });
  }

  getFood() {
    console.log('payload', this.payload);

    this.db.getFood(this.payload).subscribe((food) => {
      this.products[1] = food;
      console.log('food', food);
    });
  }

  addToCheck(item: Item) {
    console.log('this.ticket', this.ticket);
    console.log('item', item);
    // If the item already exists, add 1 to quantity
    if (_.some(this.ticket, {'key': item.key})) {
      _.find(this.ticket, {'key': item.key}).quantity += 1;
    } else {
      this.ticket.push(item);
    }
    this.syncTicket();
    this.calculateTotal();
  }

  // Reduce quantity by one
  subtractOne(item: Item) {
    console.log('this.ticket', this.ticket);
    console.log('item', item);
    // Check if last item, if so, use remove method
    if (_.find(this.ticket, {'key': item.key}).quantity === 1) {
      this.removeItem(item);
    } else {
      _.find(this.ticket, {'key': item.key}).quantity--;
    }
    this.syncTicket();
    this.calculateTotal();
  }

  // subtractToCheck(item: Item) {
  //   // Check if item is in array
  //   if (_.some(this.ticket, {'key': item.key})) {
  //     // Splice the element out of the array
  //     const index = _.find(this.ticket, {'key': item.key}).quantity += 1;      
  //     if (index > -1) {
  //       // Set item quantity back to 1 (thus when readded, quantity isn't 0)
  //       this.ticket[this.ticket.indexOf(item)].quantity = 1;
  //       this.ticket.splice(index, 1);
  //     }
  //   }
  //   this.syncTicket();
  //   this.calculateTotal();
  // }

  // Remove item from ticket
  removeItem(item: Item) {
    const prompt = confirm(`Are you sure to remove ${item.name} from the order?`);

    if (!prompt) return;
    // Check if item is in array
    if (this.ticket.includes(item)) {
      // Splice the element out of the array
      const index = this.ticket.indexOf(item);
      if (index > -1) {
        // Set item quantity back to 1 (thus when readded, quantity isn't 0)
        this.ticket[this.ticket.indexOf(item)].quantity = 1;
        this.ticket.splice(index, 1);
      }
    }
    this.syncTicket();
    this.calculateTotal();
  }

  // Calculate cart total
  calculateTotal() {
    let total = 0;
    let cartNum = 0;
    // Multiply item price by item quantity, add to total
    this.ticket.forEach(function(item: Item) {
      total += (item.price * item.quantity);
      cartNum += item.quantity;
    });
    this.cart_total = total;
    this.cart_num_items = cartNum;
    this.ticketSync.updateNumItems(this.cart_num_items);
    this.ticketSync.updateTotal(this.cart_total);
  }

  syncTicket() {
    this.ticketSync.changeTicket(this.ticket);
  }

  getBadge(item) {
    if (_.some(this.ticket, {'key': item.key}) === true) {
      // console.log('find ticket', _.find(this.ticket, {'key': item.key}));
      return _.find(this.ticket, {'key': item.key})['quantity'];
    }
  }

}
