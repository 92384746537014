
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { Observable ,  of ,  BehaviorSubject } from 'rxjs';
import { User } from './user';
import { DatabaseService} from './../database.service';

@Injectable()
export class AuthService {

  authState: any = null;
  user: BehaviorSubject<User> = new BehaviorSubject(null);

  currentUID: string;
  admin: boolean;

  constructor(private afAuth: AngularFireAuth, private db: DatabaseService, private router: Router) {
    this.afAuth.authState.pipe(
    map(auth => {
      if (auth) {
        /// signed in
        console.log('auth', auth);
        // return this.afs.doc<User>('users/' + auth.uid).valueChanges();
        return this.db.getSchoolUid(auth.uid).then((result) => {
          console.log('result.val()', result.val());
          
          localStorage.setItem('school_uid', result.val());
          this.db.school_uid = result.val();
          
          this.checkAdmin(auth.uid);

          const payload = {
            school_uid: result.val()
          };

          return this.db.adb.object(`schools/${payload.school_uid}`).valueChanges();
        });
      } else {
        /// not signed in
        return of(null);
      }
    // })
    // .subscribe(user => {
    //   this.user.next(user);
    }));
  }

  signup(email: string, password: string) {
    this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(userData => {
      const newUser: User = {email: email, cashier: true, admin: false};
      // this.afs.collection('users').doc(userData.uid).set(newUser);
    });

  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
    .then((user) => {
      this.authState = user;
      console.log('Login success');
      this.currentUID = this.afAuth.auth.currentUser.uid;
      this.admin = true;
      this.checkAdmin(this.currentUID);
      this.router.navigate(['/welcome']);
    });
  }

  anonymousLogin() {
    return this.afAuth.auth.signInAnonymously()
      .then((user) => {
        this.authState = user;
        console.log('Login success');
        this.currentUID = 'guest';
        this.checkAdmin(this.currentUID);
        this.router.navigate(['/welcome']);
      });
  }

  resetPassword(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(email)
      .then(() => console.log('email sent'));
  }

  updateUserRole(id, roles) {
    // this.db.updateUser(id, roles);
  }

  checkAdmin(uid) {
    console.log('auth uid', uid);
    if (uid !== 'guest') {
      const payload = {
        school_uid: this.db.school_uid,
        uid
      };
      console.log('payload', payload);
      
      this.db.getUser(payload).then((data) => {
        const user_profile = data.val();
        console.log('data auth', user_profile);
        this.admin = user_profile['role'] === 'canteen_admin' ? true : false;
      });
    } else {
      this.admin = false;
    }
  }

  signOut(): void {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['/']);
      this.authState = null;
      this.currentUID = null;
      this.admin = false;
    });
    console.log('Logged out');
  }

}
