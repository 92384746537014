import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr-verification-dialog',
  templateUrl: './qr-verification-dialog.component.html',
  styleUrls: ['./qr-verification-dialog.component.scss']
})
export class QrVerificationDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
