import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatabaseService } from './../../../database.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-cashless-dialog',
  templateUrl: './cashless-dialog.component.html',
  styleUrls: ['./cashless-dialog.component.scss']
})
export class CashlessDialogComponent implements OnInit {

  profile: any;

  constructor(public dialogRef: MatDialogRef<CashlessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private db: DatabaseService) {
  }

  ngOnInit() {
    let history_payload = this.data;
    history_payload['school_uid'] = this.db.school_uid;
    console.log('history_payload', history_payload);
    
    this.db.getProfile(history_payload).then((user_result) => {
      console.log('user_result', user_result.val());
      this.profile = user_result.val();
    });
  }

  processScan() {
    let payload = this.data;
    console.log('payload for cashless', payload);

    if (payload.scan_op === 'buy' && payload.type === 'debit') {
      this.db.pushOrderCashless(payload).then((new_order) => {
        let history_payload = this.data;
        history_payload['new_balance'] = {
          amount: this.data['order_data']['total'],
          order_id: new_order['key'],
          balance: payload.balance - this.data['order_data']['total'],
          type: payload.type
        };
        // history_payload['uid'] = payload['buyer_uid'];

        this.db.updateOrderHistory(history_payload).then(() => {
          console.log('success order and update records');
          this.dialogRef.close(true);
        });
      });
    }

    if (payload.scan_op === 'buy' && payload.type === 'utang') {
      this.dialogRef.close(payload);
    }

    if (payload.scan_op === 'check_balance') {
      this.dialogRef.close(payload);
    }

    if (payload.scan_op === 'top_up') {
      this.dialogRef.close(payload);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
