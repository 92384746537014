export interface Item {
  id?: number;
  key?: number;
  name: string;
  price: number;
  item_type: string;
  img_name?: string;
  img_url?: string;
  quantity?: number;
}

export interface Order {
  order_number: string;
  items?: Item[];
  cart_total?: number;
  cart_num_items?: number;
  method: string;
  type: string;
  buyer_type?: string;
  buyer_uid?: string;
  buyer_name?: string;
  amount: number;
}

export class Upload {
  $key: string;
  file: File;
  name: string;
  url: string;
  progress: number;
  createdAt: Date = new Date();

  constructor(file: File) {
    this.file = file;
  }
}
