import { Component, OnInit, Inject } from '@angular/core';
import { trigger, transition, useAnimation, animate, style } from '@angular/animations';
import { flipInY, flipOutY, slideInLeft, slideOutLeft, slideInRight, slideOutRight, fadeIn, fadeOut } from 'ng-animate';
import { DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('flipInY', [transition('* => *', useAnimation(flipInY))]),
    trigger('flipOutY', [transition('* => *', useAnimation(flipOutY))]),
    trigger('slideInLeft', [transition('* => *', useAnimation(slideInLeft, {
      params: { timing: 0.3, delay: 0.5 }
    }))]),
    trigger('slideOutLeft', [transition('* => *', useAnimation(slideOutLeft, {
      params: { timing: 0.3, delay: 0 }
    }))]),
    trigger('slideInRight', [transition('* => *', useAnimation(slideInRight, {
      params: { timing: 0.3, delay: 0.5 }
    }))]),
    trigger('slideOutRight', [transition('* => *', useAnimation(slideOutRight, {
      params: { timing: 0.3, delay: 0 }
    }))]),
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))]),
    trigger('fadeOut', [transition('* => *', useAnimation(fadeOut))])
  ]
})
export class HomeComponent implements OnInit {
  
  selected_tab: string;
  camera_device: string;
  flipInX = false;
  flipInYItems = false;
  flipInYTicket = false;
  slideInLeftItems = false;
  slideInLeftTicket = false;
  slideInRightItems = false;
  slideInRightTicket = false;
  flipOutX = false;
  flipOutYItems = false;
  flipOutYTicket = false;
  slideOutLeftItems = false;
  slideOutLeftTicket = false;
  slideOutRightItems = false;
  slideOutRightTicket = false;
  fadeIn = false;
  fadeOut = false;

  constructor() { 
    this.selected_tab = 'pos';
  }

  ngOnInit() {
  }

  animate(name: 'string') {
    console.log('toggle', name);
    this[name] = !this[name];
  }

  selectTab(tab) {
    console.log('tab selected', tab);
    this.selected_tab = tab;
  }

}
