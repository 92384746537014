import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { DatabaseService } from './../database.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

    school_profile: any;
    app_version: string;

  constructor(private auth: AuthService, private db: DatabaseService) {
    this.app_version = this.db.app_version;
    this.db.getSchoolProfile().then((result) => {
      this.school_profile = result;
    });
  }

  ngOnInit() {
  }

  logout() {
    this.auth.signOut();
  }


}
