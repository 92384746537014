import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatabaseService } from './../../../database.service';

@Component({
  selector: 'app-cash-dialog',
  templateUrl: './cash-dialog.component.html',
  styleUrls: ['./cash-dialog.component.scss']
})
export class CashDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CashDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private db: DatabaseService) { }

  ngOnInit() {
  }

  pushOrderCash() {
    console.log('total', this.data['order_data']['total']);

    const payload = this.data;

    this.db.pushOrderCash(payload).then(() => {
      this.dialogRef.close(true);
    });
  }

  cancel() {
    this.dialogRef.close();
  }

}
