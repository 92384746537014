import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Item } from './item';

@Injectable()
export class PosService {

  private ticket = TICKET;
  private ticketSource = new BehaviorSubject<Item[]>(this.ticket);

  private cart_total = 0;
  private cart_totalSource = new BehaviorSubject<number>(this.cart_total);

  private cart_num_items = 0;
  private cartNumSource = new BehaviorSubject<number>(this.cart_num_items);


  currentTicket = this.ticketSource.asObservable();
  currentTotal = this.cart_totalSource.asObservable();
  currentCartNum = this.cartNumSource.asObservable();

  constructor() { }

  changeTicket(ticket: Item[]) {
    this.ticketSource.next(ticket);
  }

  updateTotal(total: number) {
    this.cart_totalSource.next(total);
  }

  updateNumItems(num: number) {
    this.cartNumSource.next(num);
  }

}

// Demo content
const TICKET: Item[] = [
];
