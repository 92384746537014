import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from '../core/app-routing.module';
import { FormsModule } from '@angular/forms';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { WebcamModule } from 'ngx-webcam';

import { HistoryComponent } from './history/history.component';
import { PosComponent } from './pos/pos.component';
import { TopUpComponent } from './top-up/top-up.component';
import { CheckBalanceComponent } from './check-balance/check-balance.component';
import { TicketComponent } from './ticket/ticket.component';
import { QrDialogComponent } from './ticket/qr-dialog/qr-dialog.component';
import { HomeComponent } from './home.component';
import { LineItemModalComponent } from './history/line-item-modal/line-item-modal.component';
import { TransactionsComponent } from './transactions/transactions.component';

import { AngularMaterialModule } from './../angular-material.module';

import { ReportsComponent } from './reports/reports.component';
import { LineChartComponent } from './reports/line-chart/line-chart.component';

import { ChartsModule } from 'ng2-charts/ng2-charts';
import { CashDialogComponent } from './ticket/cash-dialog/cash-dialog.component';
import { UtangDialogComponent } from './ticket/utang-dialog/utang-dialog.component';
import { CheckoutSummaryComponent } from './checkout-summary/checkout-summary.component';
import { CashlessDialogComponent } from './ticket/cashless-dialog/cashless-dialog.component';
import { QrVerificationDialogComponent } from './ticket/qr-verification-dialog/qr-verification-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ChartsModule,
    NgQrScannerModule,
    WebcamModule,
    BrowserAnimationsModule,
    AngularMaterialModule
  ],
  declarations: [
    HomeComponent,
    TicketComponent,
    LineItemModalComponent,
    PosComponent,
    HistoryComponent,
    TransactionsComponent,
    ReportsComponent,
    LineChartComponent,
    QrDialogComponent,
    TopUpComponent,
    CheckBalanceComponent,
    CashDialogComponent,
    UtangDialogComponent,
    CheckoutSummaryComponent,
    CashlessDialogComponent,
    QrVerificationDialogComponent,
  ],
  entryComponents: [
    LineItemModalComponent, 
    QrDialogComponent,
    CashDialogComponent, 
    UtangDialogComponent,
    CheckoutSummaryComponent,
    CashlessDialogComponent
  ]
})
export class HomeModule { }
