import {Component, Inject, ViewChild, OnInit, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { QrScannerComponent } from 'angular2-qrscanner';
import * as _ from 'lodash';
import { DatabaseService } from './../../../database.service';
import { CashlessDialogComponent } from '../cashless-dialog/cashless-dialog.component';

@Component({
    selector: 'qr-dialog',
    templateUrl: 'qr-dialog.component.html',
  })
  export class QrDialogComponent implements OnInit {

    @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent;
    users: any;
    scannedIDTemp: string = '';
    scannedID: string = '';
  
    found_staff: boolean;
    found_student: boolean;
  
    not_found: boolean;

    scanned_staff: object;
    scanned_student: object;

    staff: Array<any>;
    staffHistory: Array<any>;
    students: Array<any>;
    studentsHistory: Array<any>;

    constructor(
      public dialogRef: MatDialogRef<QrDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private db: DatabaseService, public dialog: MatDialog) {
        console.log('this.data', this.data);          
      }
  
    // onNoClick(): void {
    //   this.dialogRef.close();
    // }

    @HostListener('window:keypress', ['$event'])
    keyEvent(event: KeyboardEvent) {
      // console.log(event);
      if (event.keyCode !== 13) {
        this.scannedIDTemp += String.fromCharCode(event.which || event.keyCode);
      }
      event.stopPropagation();
      
      if (event.keyCode === 13) {
        // this.dialogRef.close();
        this.found_staff = false;
        this.found_student = false;
        // test
        // this.scannedIDTemp = `123456789012`;
        // this.scannedIDTemp = `MES.gk.2.out@classemo.com${this.keymaster}MES.gk.2.out.1207358`;
        // authenticator
        // if (_.includes(this.scannedIDTemp, this.keymaster)) {
        //   this.checkKeymaster();
        //   return;
        // }
  
        this.scannedID = this.scannedIDTemp;
        // this.scannedID = 'LLC-2017-205___9NQ75';
        // this.scannedID = '404365150023-18___9IQY9';
        console.log('this.scannedID', this.scannedID);

        this.scanQRCode(this.scannedID);
      }
    }

    ngOnInit() {
        this.getStudents();
        this.getStudentsHistory();
        this.getUsers();
        this.getUsersHistory();
    }

    enableWebCamera() {
        this.qrScannerComponent.getMediaDevices().then(devices => {
            console.log(devices);
            const videoDevices: MediaDeviceInfo[] = [];
            for (const device of devices) {
                if (device.kind.toString() === 'videoinput') {
                    videoDevices.push(device);
                }
            }
            if (videoDevices.length > 0){
                let choosenDev;
                for (const dev of videoDevices){
                    if (dev.label.includes('back')){
                        choosenDev = dev;
                        break;
                    }
                }
                if (choosenDev) {
                    this.qrScannerComponent.chooseCamera.next(choosenDev);
                } else {
                    this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
                }
            }
        });
  
        this.qrScannerComponent.capturedQr.subscribe(result => {
            console.log('qr code', result);
            this.scanQRCode(result);
        });
    }

    getStudents() {
        this.students = this.db.studentsList;
        console.log('students', this.students);
    }

    getStudentsHistory() {
        this.studentsHistory = this.db.studentsHistory;
        console.log('students history ', this.studentsHistory);
    }
    
    getUsers() {
        this.users = this.db.usersList;
        console.log('users', this.users);
    }

    getUsersHistory() {
        this.staffHistory = this.db.usersHistory;
        console.log('staff history ', this.staffHistory);
    }
  
    scanQRCode(qr_code) {
// qr_code = '404551150034-18___Y3GWF';
console.log('qr_code', qr_code);
        const found_student = _.find(this.students, {qr_code});
        console.log('found_student', found_student);
        const found_user = _.find(this.users, {qr_code});
  
        if (!found_student && !found_user) {
          console.log('qr code not found');
          return;
        }
          
        let payload = {
            // school_key: this.sc.school_key,
            data: {}
        };
        let name: string;
        // let phone_for_sms: number;
        let balance;
        let found;
        let found_buyer_type;

        if (found_user) {
            console.log('found_user', found_user);
            // this.found_staff = true;
            payload['data']['staff'] = found_user['key'];

            name = found_user['name']['firstname'];
            // phone_for_sms = found_user['contact']['phone'] || null;
            
            const found_history = _.find(this.staffHistory, {'key': found_user['key']});

            if (found_history) {
                balance = _.last(_.map(found_history, (record) => {
                    return record;
                }))['balance'];
            } else {
                balance = 0;    
            }
            console.log('balance', balance);
            
            found = found_user;
            found_buyer_type = 'staff';
        }

        if (found_student) {
            console.log('found_student', found_student);

            payload['data']['student'] = found_student['key'];

            name = found_student['name']['firstname'];
            // phone_for_sms = found_student['contact']['phone'] || null;


            const found_history = _.find(this.studentsHistory, {'key': found_student['key']});

            if (found_history) {
                balance = _.last(_.map(found_history, (record) => {
                    return record;
                }))['balance'];
            } else {
                balance = 0;    
            }
            console.log('balance', balance);

            found = found_student;
            found_buyer_type = 'student';
        }
            
            if (this.data['scan_op'] === 'buy') {
                if ((balance >= this.data['order_data']['total']) || this.data['type'] === 'utang') {
                    const buyer_data = {
                        uid: found.key,
                        buyer_uid: found.key,
                        balance,
                        order_data: this.data['order_data'],
                        buyer_type: found_buyer_type,
                        scan_op: 'buy',
                    };

                    if (this.data['type'] === 'cashless') {
                        buyer_data['type'] = 'debit';
                    }
                    if (this.data['type'] === 'utang') {
                        buyer_data['type'] = 'utang';
                    }
            
                    this.verifyIdentity(buyer_data);
                } else {
                    this.dialogRef.close();
                    alert('Not enough balance. Please tell the parent of the student to reload canteen credits.');
                }
            }
            
            if (this.data['scan_op'] === 'check_balance') {
                const checker_data = {
                    uid: found.key,
                    balance,
                    buyer_type: found_buyer_type,
                    scan_op: 'check_balance'
                };
                // this.checkBalance(checker_data);
                this.verifyIdentity(checker_data);
            }
            
            if (this.data['scan_op'] === 'top_up') {
                const loader_data = {
                    uid: found.key,
                    balance,
                    amount: this.data['loader_data']['top_up_amount'],
                    buyer_type: found_buyer_type,
                    buyer_uid: found.key,
                    scan_op: 'top_up'
                };
                // this.topUp(loader_data);
                this.verifyIdentity(loader_data);
            }

        // if (!phone_for_sms) {
        //     return;
        // }

        // phone_for_sms = this.trimMobileNumber(phone_for_sms);

        // if (_.size(phone_for_sms) === 10) {
        //     setTimeout(() => {
        //         // console.log('send SMS after 6 secs');
        //         // this.sendSMS(phone_for_sms, name, scan_label);
        //     }, 6000);
        // }
    }

    verifyIdentity(buyer_data) {
        let dialogRef = this.dialog.open(CashlessDialogComponent, {
          width: '50%',
          height: '70%',
          data: buyer_data
        });

        dialogRef.afterClosed().toPromise().then(result => {
          console.log('The cashless dialog was closed and result', result);
    
          if (result) {
            this.dialogRef.close(buyer_data);
          }
        });
    }

  }
