
import {map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';
import { Item, Order, Upload } from './item';
import { Observable } from 'rxjs';
import { AngularFireStorage } from 'angularfire2/storage';
import { finalize } from 'rxjs/operators';

@Injectable()
export class DatabaseService {

  app_version: string = '1.20.40';

  school_uid: string;

  school_profile: any;

  firebase_ref = firebase.database().ref();
  uploads: Observable<Upload[]>;
  lastUpload: Upload;
  lastUploadUrl: String;
  
  studentsList: any;
  usersHistory: any;
  studentsHistory: any;
  usersList: any;
  ordersList: AngularFireList<any[]>;
  uploadPercent: Observable<number>;

  constructor(public adb: AngularFireDatabase, private afs: AngularFireStorage) {
    if (localStorage.getItem('school_uid')) {
      this.school_uid = localStorage.getItem('school_uid');
    }
  }

  updateNumRequested(payload) {
    this.ordersList = this.adb.list(`schools/${payload.school_uid}/canteen/orders`, ref => ref.orderByChild('order_number'));
    // this.ordersCollection = this.afs.collection('past_orders', ref => ref.orderBy('order_number', 'desc').limit(num));
  }

  getSnapshot(payload) {
    return this.adb.list(`schools/${payload.school_uid}/canteen/orders`).snapshotChanges().pipe(map(actions => {
      return actions.map(a => ({ key: a.key, ...a.payload.val() }));
    }));
  }

  getOrder(payload) {
    return this.firebase_ref.child(`schools/${payload.school_uid}/canteen/orders/${payload.order_id}`).once('value');
    // return this.afs.doc<Order>('past_orders/' + id);
  }

  pushOrderCashless(payload) {
    const date = Date.now().toString();
    const ticket = {
      order_number: date, 
      items: payload.order_data.itemList, 
      cart_total: payload.order_data.total, 
      cart_num_items: payload.order_data.cart_num_items, 
      type: 'debit',
      method: 'cashless',
      buyer_type: payload.buyer_type,
      buyer_uid: payload.buyer_uid
    };

    return this.adb.list(`schools/${payload.school_uid}/canteen/orders`).push(ticket);
    // return this.ordersCollection.add(ticket);
  }

  pushOrderCash(payload) {
    const date = Date.now().toString();
    const ticket = {
      order_number: date, 
      items: payload.order_data.itemList, 
      cart_total: payload.order_data.total, 
      cart_num_items: payload.order_data.cart_num_items, 
      type: 'debit',
      method: 'cash',
    };

    return this.adb.list(`schools/${payload.school_uid}/canteen/orders`).push(ticket);
  }

  pushOrderUtang(payload) {
    const date = Date.now().toString();
    const ticket = {
      order_number: date, 
      items: payload.order_data.itemList, 
      cart_total: payload.order_data.total, 
      cart_num_items: payload.order_data.cart_num_items, 
      type: payload.type,
      method: 'utang',
    };

    if (payload.buyer_name) {
      ticket['buyer_name'] = payload.buyer_name;
    }
    if (payload.buyer_uid) {
      ticket['buyer_uid'] = payload.buyer_uid;
      ticket['buyer_type'] = payload.buyer_type;
    }

    return this.adb.list(`schools/${payload.school_uid}/canteen/orders`).push(ticket);
  }

  pushTopUp(payload) {
    const date = Date.now().toString();
    const ticket = {
      order_number: date, 
      amount: payload.amount, 
      type: 'credit',
      method: 'cash',
      buyer_id: payload.buyer_uid,
      buyer_type: payload.buyer_type
    };

    return this.adb.list(`schools/${payload.school_uid}/canteen/orders`).push(ticket);
  }

  checkBalance(payload) {
    let ref;
    
    if (payload.buyer_type === 'student') {
      ref = `schools/${payload.school_uid}/canteen_history_students/${payload.uid}`;
    }
    if (payload.buyer_type === 'staff') {
      ref = `schools/${payload.school_uid}/canteen_history_staff/${payload.uid}`;
    }

    return this.firebase_ref.child(ref).once('value');
  }

  updateOrderHistory(payload) {
    console.log('payload for updare order history', payload);
    let ref;
    
    if (payload.buyer_type === 'student') {
      ref = `schools/${payload.school_uid}/canteen_history_students/${payload.uid}`;
    }
    if (payload.buyer_type === 'staff') {
      ref = `schools/${payload.school_uid}/canteen_history_staff/${payload.uid}`;
    }

    payload['new_balance']['date'] = firebase.database.ServerValue.TIMESTAMP;
    return this.adb.list(ref).push(payload.new_balance);
  }

  deleteOrder(id) {
    // return this.getOrder(id).delete();
  }

  // Edit POS Items

  // Type selects the right collection to query for ID
  getItem(payload) {
    console.log('payload getItem', payload);

    const collection = _.toLower(payload.type);

    // return this.afs.doc<Item>(collection + id);
    return this.adb.object(`schools/${payload.school_uid}/canteen/${collection}/${payload.id}`);
  }

  deleteItem(id, type, img) {
    this.deleteUpload(img, type);
    const payload = {
      school_uid: this.school_uid,
      id, 
      type
    };
    // return this.getItem(id, type).delete();
    return this.getItem(payload).remove();
  }

  updateItem(payload) {
    const item: Item = {
      name: payload.name, 
      price: payload.price, 
      item_type: payload.type, 
      quantity: 1, 
      img_name: payload.upload.name, 
      img_url: payload.upload.url
    };

    if (payload.type === 'Food') {
      return this.adb.object(`schools/${payload.school_uid}/canteen/food/${payload.item.key}`).update(item);
    } else if (payload.type === 'Drink') {
      return this.adb.object(`schools/${payload.school_uid}/canteen/drink/${payload.item.key}`).update(item);
    }
  }

  addItemInfo(payload) {
  // addItemInfo(name: string, price: number, type: string, img_name: string, img_url: string) {
    const item: Item = {
      name: payload.name, 
      price: payload.price, 
      item_type: payload.type, 
      quantity: 1, 
      img_url: payload.upload.url
    };

    return this.adb.list(`schools/${payload.school_uid}/canteen/${_.toLower(payload.type)}`).push(item);
  }

  getFood(payload) {
    return this.adb.list(`schools/${payload.school_uid}/canteen/food`).snapshotChanges().pipe(map(actions => {
      return actions.map(a => ({ key: a.key, ...a.payload.val() }));
    }));
  }

  getDrink(payload) {
    return this.adb.list(`schools/${payload.school_uid}/canteen/drink`).snapshotChanges().pipe(map(actions => {
      return actions.map(a => ({ key: a.key, ...a.payload.val() }));
    }));
    // return this.drinkCollection.snapshotChanges().map(actions => {
    //   return actions.map(a => {
    //     return { id: a.payload.doc.id, ...a.payload.doc.data() };
    //   });
    // });
  }

  getUser(payload) {
    // return this.afs.doc<User>('users/' + id);
    return this.firebase_ref.child(`schools/${payload.school_uid}/user_profiles_list/${payload.uid}`).once('value');
  }

  // deleteUser(id) {
  //   this.getUser(id).delete();
  // }

  // updateUser(id, roles) {
  //   return this.getUser(id).update(roles);
  // }

  pushUpload(payload) {
    console.log('payload for upload', payload);
  // pushUpload(name: string, price: number, type: string, upload: Upload) {

    const file = payload.upload.file;
    const filePath = `/${payload.school_uid}/canteen/${_.toLower(payload.type)}/${payload.upload.file.name}`;
    const fileRef = this.afs.ref(filePath);
    const task = this.afs.upload(filePath, file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();

    return task.snapshotChanges().pipe(
      finalize(() => {
        // this.downloadURL = fileRef.getDownloadURL();
        return fileRef.getDownloadURL().subscribe((photo_url) => {
          payload['upload']['url'] = photo_url;
          // this.downloadURL = fileRef.getDownloadURL();
          // payload['downloadURL'] = this.downloadURL;

          console.log('downloadURL', payload.upload.url);

          if (payload.op === 'save') {
            this.addItemInfo(payload);
          }
          if (payload.op === 'update') {
            this.updateItemInfo(payload);
          }
        });
      })
    ).toPromise();
  }

  updateItemInfo(payload) {
    const data_update: Item = {
      name: payload.name, 
      price: payload.price, 
      item_type: payload.type,
      quantity: 1
    };

    if (payload.upload && payload.upload.url) {
      data_update['img_url'] = payload.upload.url;
    }
    console.log('data_update for image upload', data_update);

    return this.adb.object(`schools/${payload.school_uid}/canteen/${_.toLower(payload.type)}/${payload.id}`).update(data_update);
  }

  deleteUpload(name: string, type) {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`/canteen/${type}/name`);
    imageRef.delete();
  }

  getSchoolUid(uid) {
    return this.firebase_ref.child(`users/${uid}/school`).once('value');
  }

  getStudents(payload) {
    return this.adb.list(`schools/${payload.school_uid}/students`).snapshotChanges().pipe(map(actions => {
      return actions.map(a => ({ key: a.key, ...a.payload.val() }));
    })).subscribe((students) => {
      this.studentsList = students;
    });
  }

  getStudent(payload) {
    return this.adb.object(`schools/${payload.school_uid}/students/${payload.uid}`).valueChanges();
  }

  getProfile(payload) {
    let ref;

    if (payload.buyer_type === 'student') {
      ref = `schools/${payload.school_uid}/students/${payload.uid}`;
    }
    if (payload.buyer_type === 'staff') {
      ref = `schools/${payload.school_uid}/user_profiles_list/${payload.uid}`;
    }

    return this.firebase_ref.child(ref).once('value');
  }

  getSchoolProfile() {
    console.log('this.school_uid', this.school_uid);
    const ref = `schools/${this.school_uid}/profile`;
    return this.firebase_ref.child(ref).once('value').then((result) => {
      console.log('result school profile', result.val());
      return this.school_profile = result.val();
    });
  }

  getStudentsHistory(payload) {
    return this.adb.list(`schools/${payload.school_uid}/canteen_history_students`).snapshotChanges().pipe(map(actions => {
      return actions.map(a => ({ key: a.key, ...a.payload.val() }));
    })).subscribe((students_history) => {
      this.studentsHistory = students_history;
    });
  }

  getUsers(payload) {
    return this.adb.list(`schools/${payload.school_uid}/user_profiles_list`).snapshotChanges().pipe(map(actions => {
      return actions.map(a => ({ key: a.key, ...a.payload.val() }));
    })).subscribe((users) => {
      this.usersList = users;
    });
  }

  getUsersHistory(payload) {
    return this.adb.list(`schools/${payload.school_uid}/canteen_history_staff`).snapshotChanges().pipe(map(actions => {
      return actions.map(a => ({ key: a.key, ...a.payload.val() }));
    })).subscribe((staff_history) => {
      this.usersHistory = staff_history;
    });
  }

}
