
import {tap, map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AngularFireAuth } from 'angularfire2/auth';



import { DatabaseService } from './../database.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private afAuth: AngularFireAuth, private router: Router, private db: DatabaseService, private authServ: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.afAuth.authState.pipe(
            take(1),map(user => !!user),
            tap(loggedIn => {
              if (!loggedIn) {
                console.log('access denied');
                this.router.navigate(['/login']);
              } else {
                const uid = this.afAuth.auth.currentUser.uid;
                console.log('loggedIn user', uid);
                
                return this.db.getSchoolUid(uid).then((result) => {
                  console.log('result.val()', result.val());
                  this.db.school_uid = result.val();
                  localStorage.setItem('school_uid', result.val());

                  this.authServ.checkAdmin(uid);
        
                  const payload = {
                    school_uid: result.val()
                  };

                  this.db.getStudents(payload);
                  this.db.getStudentsHistory(payload);
                  this.db.getUsers(payload);
                  this.db.getUsersHistory(payload);
        
                  return this.db.adb.object(`schools/${payload.school_uid}`).valueChanges();
                });
              }
            }),);
    }
}
