import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from './../../../database.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { QrDialogComponent } from '../qr-dialog/qr-dialog.component';

@Component({
  selector: 'app-utang-dialog',
  templateUrl: './utang-dialog.component.html',
  styleUrls: ['./utang-dialog.component.scss']
})
export class UtangDialogComponent implements OnInit {

  utanger: string;

  constructor(public dialogRef: MatDialogRef<UtangDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private db: DatabaseService, public dialog: MatDialog) { }

  ngOnInit() {
  }

  openQRDialog(): void {
    let dialogRef = this.dialog.open(QrDialogComponent, {
      width: '50%',
      height: '25%',
      data: this.data
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('The QR dialog was closed and result', result);

      if (result) {
        result['school_uid'] = this.db.school_uid;
        this.pushOrderUtang(result).then((new_order) => {
          const history_payload = this.data;
          history_payload['new_balance'] = {
            amount: history_payload['order_data']['total'],
            order_id: new_order['key'],
            balance: result.balance - history_payload['order_data']['total'],
            type: history_payload['type']
          };

          history_payload['uid'] = result['buyer_uid'];
          history_payload['buyer_type'] = result['buyer_type'];
      console.log('history_payload', history_payload);
    
          this.db.updateOrderHistory(history_payload).then(() => {
            console.log('success order and update records');
            this.dialogRef.close(true);
          });
        });
      }
    });
  }

  tenderWalkIn() {
    const payload = this.data;

    if (this.utanger) {
      payload['buyer_name'] = this.utanger;
    }

    this.pushOrderUtang(payload).then(() => {
      this.utanger = null;
      this.dialogRef.close(true);
    });
  }

  pushOrderUtang(payload) {
    console.log('payload utang', payload);

    return this.db.pushOrderUtang(payload);
  }

}
