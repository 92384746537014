import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { PosService } from '../../pos.service';
import { DatabaseService } from '../../database.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {QrDialogComponent} from './../ticket/qr-dialog/qr-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-top-up',
  templateUrl: './top-up.component.html',
  styleUrls: ['./top-up.component.scss']
})
export class TopUpComponent implements OnInit {

  school_uid: string;
  payload: object;
  top_up_digits: string = ''; 
  amount: number;
  balance_history: any = [];
  scanned_person: any;
  scanned_type: string;

  constructor(private ticketSync: PosService, private db: DatabaseService, public dialog: MatDialog) { }

  ngOnInit() {
    this.school_uid = this.db.school_uid;
    this.payload = {
      school_uid: this.school_uid
    };
  }

  inputTopUp(val) {
    this.top_up_digits = this.top_up_digits + val;
  }

  deleteLastDigit() {
    this.top_up_digits = this.top_up_digits.slice(0, -1);
  }

  clearInput() {
    this.top_up_digits = null;
  }

  topUp() {
    this.amount = +this.top_up_digits;
    console.log('amount', this.amount);

    if (this.amount > 0) {
      const payload = {
        school_uid: this.db.school_uid,
        loader_data: {
          top_up_amount: this.amount
        },
        scan_op: 'top_up'
      };

      this.openDialog(payload);
    }
  }

  openDialog(data): void {
    let dialogRef = this.dialog.open(QrDialogComponent, {
      width: '50%',
      height: '25%',
      data
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('The top up dialog was closed', result);

      if (result) {
        result['school_uid'] = this.db.school_uid;
        
        const history_payload = result;
        history_payload['new_balance'] = {
          amount: result.amount,
          balance: result.balance + result.amount,
          type: 'credit',
          method: 'cash'
        };
        console.log('history_payload', history_payload);

        this.db.pushTopUp(history_payload).then((new_order) => {
          history_payload['order_id'] = new_order['key'];

          this.db.updateOrderHistory(history_payload).then(() => {
            this.db.checkBalance(history_payload).then((list) => {
              this.db.getProfile(history_payload).then((user_result) => {
                const balance_history = list.val();
                const profile = user_result.val();
        
                this.balance_history = _.orderBy(_.map(balance_history, (obj) => {
                  return obj;
                }), ['date'], ['desc']);
        
                this.scanned_person = profile;
                this.scanned_type = result.buyer_type;
              
                this.clearInput();
              });
            });
          });
        });
      }
    });
  }

}
