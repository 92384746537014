import { Component, OnInit } from '@angular/core';
import { DatabaseService } from './../../database.service';
import { Upload } from './../../item';
import { Ng2ImgMaxService } from 'ng2-img-max';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material';
import { SpinnerComponent } from '../../spinner/spinner.component';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  productTypes = ['Drink', 'Food'];
  addItemActive = false;

  new_item_price: number;
  new_item_name: string;
  new_item_type: string;

  update_item_price: number;
  update_item_name: string;
  update_item_type: string;

  products = [];

  food;
  drink;
  selected_files: FileList;
  current_upload: Upload;

  school_uid: string;
  payload: object;
  
  spinner: MatDialog;
  
  constructor(private db: DatabaseService, private ng2ImgMaxService: Ng2ImgMaxService, public dialog: MatDialog) { }

  ngOnInit() {
    this.school_uid = this.db.school_uid;
    this.payload = {
      school_uid: this.school_uid
    };
    this.getDrink();
    this.getFood();
  }

  getDrink() {
    console.log('payload', this.payload);

    this.db.getDrink(this.payload).subscribe((drink) => {
      this.products[0] = drink;
      console.log('drink', drink);
    });
  }

  getFood() {
    console.log('payload', this.payload);

    this.db.getFood(this.payload).subscribe((food) => {
      this.products[1] = food;
      console.log('food', food);
    });
  }

  addItemToggle() {
    this.new_item_price = null;
    this.new_item_name = null;
    this.new_item_type = null;
    if (this.addItemActive === true) {
      this.addItemActive = false;
    } else {
      this.addItemActive = true;
    }
  }

  addItem() {
    this.openSpinner();

    const file = this.selected_files.item(0);

    const payload = {
      school_uid: this.db.school_uid,
      name: this.new_item_name,
      price: this.new_item_price,
      type: this.new_item_type,
      op: 'save'
    };

    this.ng2ImgMaxService.resize([file], 10000, 100).subscribe((result)=>{
      this.current_upload = new Upload(result);

      payload['upload'] = this.current_upload;

      this.db.pushUpload(payload).then(() => {
        this.closeSpinner();
      });

      this.new_item_name = null;
      this.new_item_price = null;
      this.new_item_type = null;
      this.selected_files = null;
      this.current_upload = null;
    });
  }

  openSpinner() {
    const spinner = this.dialog.open(SpinnerComponent, {
      width: '30%',
      height: '50%'
    });

    spinner.afterClosed().toPromise().then(() => {
      console.log('The spinner dialog was closed and result');
    });
  }

  closeSpinner() {
    this.dialog.closeAll();
  }

  detectFiles(event) {
    this.selected_files = event.target.files;
    console.log('this.selected_files', this.selected_files);
  }

  updateItem(item) {
    this.openSpinner();

    const file = this.selected_files && this.selected_files.item(0);

    const payload = {
      school_uid: this.db.school_uid,
      name: item.name,
      price: item.price,
      type: item.item_type,
      id: item.key,
      op: 'update'
    };

    console.log('payload for update item', payload);

    if (file) {
      this.ng2ImgMaxService.resize([file], 10000, 100).subscribe((result)=>{
        this.current_upload = new Upload(result);

        payload['upload'] = this.current_upload;

        this.db.pushUpload(payload).then(() => {
          this.closeSpinner();
        });
      });
    } else {
      this.db.updateItemInfo(payload).then(() => {
        this.closeSpinner();
      });
    }

      this.update_item_name = null;
      this.update_item_price = null;
      this.update_item_type = null;
      this.selected_files = null;
      this.current_upload = null;
  }

  deleteItem(id, name, type, img) {
    const prompt = confirm(`Are you sure you want to delete ${name}?`);

    if (!prompt) return;
    
    this.db.deleteItem(id, type, img);
  }

}
